
#accommodationAccordion {
	margin-top: 0;

	.card {
		// border-color: $primary;
		// height: auto !important;

		&:last-of-type {
			// border-color: $primary;
		}
		.card-header {
			h2 {
				button {
					font-size: $h6-font-size;
					color: $primary;
					font-style: normal;

					&::after {
						font-size: $h6-font-size;
					}

					&[aria-expanded='true'] {
						color: $primary;
						&:after {
							content: "\f068";
						}
					}
				}
			}
		}

		p {
			i {
				color: $primary;
				font-size: $h6-font-size;
			}
		}
	}
}