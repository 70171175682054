//last section padding bottom
main {
	position: relative;
	z-index: 100 !important;
}
main section {
	&:last-child() {
		padding-bottom: 18vh;
	}
}
// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
	text-underline-offset: 5px;
}

// form
.form {
	padding: 15px;
	label {
		font-weight: 700;
		display: flex;
		align-items: center;
	}
	.form-group:has(input:valid) {
		position: relative;
		label:after {
			content: "\f14a";
			margin-left: 0.65rem;
			font-weight: 600;

			font-family: $font-awesome;
			color: $green;
		}
	}
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 10px;
	.card-image {
		border-radius: 10px;
	}
	.card-title {
		color: $green-dark;
	}
	.card-subtitle {
		font-family: $font-family-primary;
		font-size: $h5-font-size;
	}
	.card-btn {
		background: $green-dark;
		border: none;
	}
}

// card-assortiment
.card-assortiment {
	.card-image-caption {
		position: relative;
		height: 100%;
	}
	.card-image-label {
		position: absolute;
		top: 0;
		left: 0;
		background: $green-dark;
		padding: 15px 1rem;
		border-radius: 0 0 12px 0;
		font-weight: 700;
	}
}

// images
.gallery-link,
.gallery-img {
	border-radius: 10px !important;
}

.gallery-label {
	position: absolute;
	top: 0;
	left: 0;
	color: $white;
	padding: 15px 1rem;
	border-radius: 0 0 12px 0;
	font-weight: 700;
}
