// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-title {
		color: #fff;
		text-align: center;
		font-family: $font-family-secondary;
		font-size: 4rem;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		max-width: 60%;
		margin: auto;
		@include media-breakpoint-down(lg) {
			font-size: 40px;
			font-weight: 700;
			line-height: 45.96px;
			text-align: center;
			transform: translateY(-100%);
			max-width: 90%;
		}
		@media (max-width: 360px) {
			font-size: 32px;
			margin-top: 3vh;
			max-width: 90%;
		}
	}
	.owl-subtitle,
	.owl-btn,
	.owl-description,
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 65vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
}
@keyframes tiktok {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
section.eyecatcher.large {
	position: relative;
	.clock-container {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 990;
		height: 100%;
		max-width: 20%;
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			z-index: 90;
		}
		.container-holder {
			height: 100%;
			.column {
				display: flex;
				flex-flow: column;
				justify-content: center;
				height: 100%;
				padding-left: 2.5rem;
				@include media-breakpoint-down(lg) {
					z-index: 99;
					justify-content: flex-end;
					padding-bottom: 6vh;
				}
				.clock {
					position: relative;
					width: fit-content;
					transform: translateX(23px);
					img {
						display: block;
						position: relative;
						position: relative;
					}
					&::before {
						content: url("/images/last-minutes.png");
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -25%);
						z-index: 989;
					}

					&::after {
						content: "";
						position: absolute;
						left: calc(50% - 1rem);
						top: 14%;
						height: 80%;
						width: 2rem;
						background: url("/images/clock-hand.svg");
						background-position: top 7px center;
						background-repeat: no-repeat;
						background-size: 100%;
						animation: tiktok 60s steps(60) infinite;
					}
					@include media-breakpoint-down(lg) {
						transform: scale(0.7);
					}
				}
				div.clock-button {
					margin-top: -1rem;
					@include media-breakpoint-down(lg) {
						transform: scale(0.7) translate(-17%, -45%);
						z-index: -1;
					}
					a {
						display: block;
						background: $blue-dark;
						color: $white;
						text-decoration: none;
						border: 3px solid #ffffff;
						width: 186px;
						max-width: 186px;
						border-radius: 8px 0px 0px 0px;
						padding: 1.2rem;
						font-family: $font-family-primary;
						font-size: 16px;
						font-weight: 700;
						line-height: 19.36px;
						text-align: center;
						&:hover {
							background: $blue-light;
						}
					}
				}
			}
		}
	}
}
