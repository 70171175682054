// header
@keyframes toGreen {
	to {
		background-color: $green-light;
	}
}
.header {
	z-index: 998;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	@include media-breakpoint-down(lg) {
		z-index: unset;
	}
	&.sticky {
		box-shadow: $shadow;
		.container-fluid.bottom {
			position: fixed;
			top: 0;
			width: 100vw;
			animation: toGreen linear;
			animation-timeline: scroll();
			.column {
				height: 9vh;
			}
		}
		.logo {
			top: 6vh;
			left: 0;
			position: relative;
			width: 100px;
			transition: 0.5s ease;
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				padding-left: 2.5rem;
				padding-right: 2.5rem;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding-left: 15px;
					padding-right: 15px;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			min-height: 6vh;
			background: $body-bg;
			.column {
				height: 6vh;
				display: flex;
				justify-content: flex-end;
				@include media-breakpoint-down(lg) {
					min-height: 6vh;
					position: fixed;
					display: flex;
					left: 0;
					top: 0;
					width: 100%;
					background: $body-bg;
					z-index: 100;
				}
			}
		}
		&.bottom {
			min-height: 9vh;
			background: $blue-lighter;
			position: relative;
			.column {
				height: 9vh;
				@include media-breakpoint-down(lg) {
					height: auto;
					min-height: 8vh;
					padding: 10px 15px;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		position: relative;
		z-index: 1000;
		i {
			font-size: 24px;
			color: black !important;
			&::before {
				color: black !important;
			}
		}
	}

	// logo
	.logo {
		width: 282px;
		height: 185px;
		position: absolute;
		left: 2.5rem;
		top: -4vh;
		transition: 0.5s ease;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 140px;
			left: unset;
			position: relative;
			height: unset;
			top: unset;
			// z-index: 995;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			&#secondary_menu {
				display: none;
			}

			&:not(#secondary_menu) {
				order: 2;
				position: fixed;
				left: 0;
				top: 6vh;
				width: 100%;
				padding: 1.5rem;
				background: $blue-lighter;
				z-index: 800;
				.navbar-nav {
					padding: 1rem;

					.nav-item {
						.nav-link {
							font-family: $font-family-primary;
							color: $black;
							font-size: 16px;
							font-weight: 600;
						}

						&.active {
							.nav-link {
								color: $blue-dark !important;
								font-weight: 600;
								text-decoration: underline;
							}
						}

						.dropdown-menu {
							position: static !important;
							border: 0 !important;
							box-shadow: none !important;
							margin: 0 !important;
							padding: 0 !important;
							transform: none !important;
							background: none !important;

							&::after,
							&::before {
								display: none;
							}

							.nav-item {
								border-bottom: 0;
								padding-left: 1rem;
							}

							.nav-link {
								font-size: 16px;
								font-weight: 200;
								font-family: $font-family-primary;
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			&:not(#secondary_menu) {
				position: absolute;
				left: 50%;
				top: 0;
				max-width: fit-content;
				transform: translate(-50%, 0);
				height: 100%;

				.navbar-nav {
					height: 100%;
					display: flex;
					align-items: center;
					gap: 1.5rem;
					.nav-item {
						background-color: inherit;
						.nav-link {
							color: $black;
							font-size: 16px;
							font-weight: 700;
						}
						&.active,
						&:hover {
							.nav-link {
								color: $blue;
							}
						}
						.dropdown-menu.show {
							border: 0;
							box-shadow: none;
							background-color: $blue-lighter;
							animation: toGreen linear;
							animation-timeline: scroll();
							text-align: center;
							padding: 1rem;
							border-radius: 0 0 10px 10px;
							min-width: 13rem;
							&::after,
							&::before {
								display: none;
							}
							.nav-link {
								color: $black;
								&:hover {
									color: $blue-dark;
								}
							}
						}
					}
				}
			}
			&#secondary_menu {
				position: relative;
				margin-left: auto;
				margin-right: 0.3rem;
				max-width: fit-content;
				.navbar-nav {
					gap: 1rem;
					.nav-item {
						.nav-link {
							color: #000;
							font-family: $font-family-secondary;
							font-size: 0.8125rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							&:hover {
								text-decoration: underline;
								text-underline-offset: 5px;
							}
						}
					}
				}
			}
		}
	}

	// book-button
	.book-button {
		margin-left: auto;
		a {
			@extend .btn, .btn-primary;
		}
		@include media-breakpoint-down(lg) {
			// z-index: 996;
			position: relative;

			a {
				padding: 10px 20px !important;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 1.5rem;
		.nav-link {
			box-shadow: none !important;
			border: 0 !important;
			display: flex !important;
			flex-flow: row nowrap !important;
			align-items: center;
			// &::after {
			// }
		}
		.dropdown-menu.show {
			background-color: $body-bg !important;
			border: 0 !important;
			box-shadow: none !important;
		}
		@include media-breakpoint-down(lg) {
			margin-left: auto;
			// z-index: 1000;
		}
	}
}
