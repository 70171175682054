// footer
.footer {
	background: $white;
	position: relative;
	z-index: 100;
	.footer-main {
		.container.one,
		.container.two {
			.one {
				max-width: 65% !important;
				flex-basis: 65% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
			}
			.two {
				max-width: 35% !important;
				flex-basis: 35% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
					order: -1;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
		.container.one {
			background: $blue-dark;
			transform: translateY(-50%);
			border-radius: 12px;
			@include media-breakpoint-down(lg) {
				max-width: calc(100% - 60px);
				padding: 0 2.5rem;
			}
			.container-holder {
				height: 16vh;
				@include media-breakpoint-down(lg) {
					display: flex;
					flex-flow: column;
					height: unset;
					align-items: center;
					justify-content: center;
				}
				.column {
					height: 100%;
					display: flex;
					justify-content: flex-start;
					color: $white;
					padding: 2.5rem;
					@include media-breakpoint-down(lg) {
						padding: 1.5rem 0.5rem;
						text-align: center;
						justify-content: center;
						min-width: 80%;
						margin: 0;
					}
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						color: $white;
						font-family: $font-family-secondary;
						font-weight: 700;
					}
					p {
						margin-bottom: 0;
					}
				}
			}
			ul.vocabulary-term-list {
				display: flex;
				justify-content: flex-end;
				gap: 1rem;
				@include media-breakpoint-down(lg) {
					justify-content: center;
					padding-top: 0;
				}
				li {
					.list-item-title {
						display: none;
					}
					i {
						transition: 0.35s ease;
						color: $white;
						font-size: 24px;
						font-weight: 400;
					}
					&:hover {
						i {
							transform: translateY(-10%);
							transition: 0.354s ease;
						}
					}
				}
			}
		}
		.container.two {
			margin-bottom: 6vh;
			@include media-breakpoint-down(lg) {
				margin-top: -9vh;
			}
			.one {
				@include media-breakpoint-down(lg) {
					text-align: center;
					margin-top: 3vh;
					max-width: calc(100% - 60px) !important;
					margin: auto;
				}
			}
			.two {
				div.logo img {
					min-width: 294px;
					@include media-breakpoint-down(lg) {
						min-width: unset;
						max-width: 180px;
					}
				}
			}
		}
		.container.partners {
			ul.partner-logos {
				display: flex;
				gap: 1rem;
				@include media-breakpoint-down(lg) {
					justify-content: center;
				}
				li {
					transition: 0.35s ease;
					.list-item-title {
						display: none;
					}
					&:hover {
						transform: translateY(-10%);
						transition: 0.35s ease;
					}
				}
			}
		}
	}
	.footer-socket {
		background: $body-bg;
		margin-top: 3vh;
		.column {
			padding: 1.25rem;
			.list {
				display: flex;
				justify-content: center;
				gap: 1.75rem;
				@include media-breakpoint-down(lg) {
					flex-flow: column;
					gap: 0.5rem;
				}

				a {
					color: #000;
					font-size: 15px;
					font-weight: 400;
					line-height: 18.15px;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
