// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue-lighter:#BFE7F8;
$blue-light: #8fcbea;
$blue: #009fe3;
$blue-dark: #0167b4;
$green-light:#D3EACB;
$green: #a3cebd;
$green-dark: #52ae32;
$yellow: #fbcc01;
$teal: #9ed7d1;
$olive-light: #eeeada;
$olive: #dde298;
$pink: #c8aebf;
$red: #e30613;
$white: #fff;
$black: #3C3C3B;

// theme-colors
$primary: $blue-dark;
$secondary: $yellow;

// body
$body-bg: $olive-light;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
$font-family-primary: "Inter", sans-serif;
$font-family-secondary: "Ubuntu", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 0.9375rem; // 15px

$h1-font-size: $font-size-base * 2.6667; // 40px
$h2-font-size: $font-size-base * 2.1333; // 32px
$h3-font-size: $font-size-base * 1.8667; // 28px
$h4-font-size: $font-size-base * 1.6; // 24px
$h5-font-size: $font-size-base * 1.3333; // 20px
$h6-font-size: $font-size-base; // 15px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $green-light;
$dark: $blue-lighter;
$text-light: $black;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $blue-light;
$matrix-color-secondary: $primary;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
