// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-detail-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home-usp-section
@keyframes zoom {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
.home-usp-section {
	padding: 6vh 0 8rem 0;
	background: url("/images/intro-twirl.png");
	background-position: top left;
	background-repeat: no-repeat;
	@include media-breakpoint-down(sm) {
		padding-bottom: 10rem;
	}
	&.two {
		padding: 6vh 0;
	}
	.container {
		.container-holder {
			@include media-breakpoint-down(sm) {
				gap: 3vh;
			}
		}
		.one {
			max-width: 45% !important;
			flex-basis: 45% !important;
			@include media-breakpoint-down(sm) {
				max-width: 90% !important;
				flex-basis: 100% !important;
				margin: auto;
			}
		}
		.two {
			max-width: 55% !important;
			flex-basis: 55% !important;
			padding-left: 0 !important;
			@include media-breakpoint-down(sm) {
				max-width: 90% !important;
				flex-basis: 100% !important;
				margin: auto;
			}
			ul {
				list-style: none;
				display: flex;
				flex-flow: column;
				gap: 2rem;
				@include media-breakpoint-down(sm) {
					padding-left: 15px;
					gap: 0.5rem;
				}
				li {
					display: block;
					position: relative;
					padding-left: 22px;
					max-width: 33ch;
					transition: 0.35s ease;
					&::before {
						content: "\f004";
						position: absolute;
						top: 1px;
						left: 0;
						font-family: $font-awesome;
						font-weight: 600;
						transition: 0.5s linear;
					}

					&.active {
						font-size: 16px;
						transition: 0.35s ease;
						&::before {
							animation: zoom 0.5s linear 1;
							color: $red;
						}
					}
				}
			}
		}
	}
}

// home-about-section
.home-about-section {
	background: $green-light;
	padding-bottom: 9vh;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
		background-image: url("/images/twirl-1.svg");
		background-repeat: no-repeat;
		background-position: center right;
	}
	* {
		z-index: 20;
		@include media-breakpoint-down(lg) {
			z-index: 15;
		}
	}
	.container:not(.cta) {
		max-width: 80%;
	}
	&.two {
		padding-top: 9vh;
		background: $body-bg;
		&::after {
			background-image: url("/images/twirl-2.svg");
			background-repeat: no-repeat;
			background-position: center right;
		}
	}
	.container.cta {
		background: $blue-dark;
		border-radius: 10px;
		padding: 0 !important;
		overflow: hidden;
		transform: translateY(-50%);
		position: relative;
		z-index: 300;
		@include media-breakpoint-down(lg) {
			max-width: calc(100% - 60px) !important;
			transform: translateY(-8rem);
			z-index: 15;
		}
		.item {
			display: none;
			padding: 1rem 0;
			&:nth-child(1) {
				display: flex;
				max-width: 100% !important;
				flex-basis: 100% !important;
				@include media-breakpoint-down(lg) {
					a {
						flex-flow: column-reverse;
						.card-body {
							max-width: 100%;
							text-align: center;
						}
					}
				}
			}
			.card {
				min-width: 100%;
				flex-flow: row-reverse nowrap;
				align-items: center;
				padding: 0 2.5rem 0 1.5rem;
				background: none;
				.card-body {
					max-width: 55%;
					margin-right: auto;
					* {
						color: $white;
					}
					p {
						margin-bottom: 0;
					}
					.card-title {
						font-size: $h4-font-size;
						margin-bottom: 4px;
					}
				}
				.card-image {
					i {
						color: $white;
						font-size: 72px;
					}
				}
			}
		}
	}
	.container:not(.cta) {
		@include media-breakpoint-down(lg) {
			margin-top: -6vh;
			.column {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.title,
		.description {
			text-align: center;
			margin: auto;
		}
		.grid {
			margin-top: 6vh;

			.grid-items {
				display: flex;
				.item {
					padding-right: 50px;
					@include media-breakpoint-down(lg) {
						padding-right: 10px;
						padding-left: 10px;
					}
					.card {
						background: transparent;
						border: 0;
						box-shadow: none;
						.card-image {
							border-radius: 12px;
						}
						.card-body {
							text-align: center;
							padding-top: 2rem;
							.card-title {
								color: $black;
								font-size: 24px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
							}
							.card-subtitle {
								font-size: 20px;
								font-weight: 200;
							}
							.card-description {
								max-width: 90%;
								margin: auto;
							}
							li.list-item {
								max-width: fit-content;
								margin: auto;
							}
							.card-buttons {
								margin-top: 0;
								.card-btn {
									margin-top: 1.5rem;
									background: $green-dark;
									border: none;
									&:hover {
										background: $green;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
// home-facilities-section
.home-facilities-section {
	background: $blue-lighter;
	padding: 9vh 0;
	overflow: hidden;
	@include media-breakpoint-down(lg) {
		padding: 6vh 0;
	}
	.container.intro {
		margin-bottom: 3vh;
		.title,
		.description {
			text-align: center;
			margin: auto;
			max-width: fit-content;
			color: $black;
		}
	}

	.slider {
		max-width: 78%;
		margin-left: auto;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 10rem;
			z-index: 100;
			background: linear-gradient(
				to left,
				rgba(#fff, 0) 70%,
				$blue-lighter
			);
		}
		&.reversed {
			margin-left: unset;
			margin-right: auto;
			max-width: 75%;
			@include media-breakpoint-down(md) {
				margin-bottom: -3vh;
			}
			&::after {
				left: unset;
				right: 0;
				transform: scaleX(-1);
				z-index: unset;
			}
		}
		.owl-stage-outer {
			padding: 0 15px;
			@include media-breakpoint-down(lg) {
				padding: 0 15px 15px 15px;
			}
		}
		.owl-nav {
			z-index: 200;

			i {
				font-weight: 200;
				background: $green-dark;
				color: $white;
				border-radius: 50%;
				width: 60px;
				aspect-ratio: 1/1;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s ease;
				@include media-breakpoint-down(lg) {
					width: 33px;
					font-size: 11px;
				}
			}
			.owl-next {
				transform: translateX(-75%) translateY(-130%);
				@include media-breakpoint-down(lg) {
					transform: translateX(-90%) translateY(-240%);
				}
				&:hover {
					i {
						background: $green-light;
						color: $green-dark;
						transition: 0.5s ease;
					}
				}
			}
			.owl-prev {
				display: none;
			}
		}
		&:not(.reversed) {
			.owl-nav {
				position: absolute;
				top: 50%;
				transform: scaleX(-1);

				.owl-next {
					transform: translateX(-120%) translateY(-120%);
					@include media-breakpoint-down(lg) {
						transform: translateX(-90%) translateY(-190%);
					}
				}
			}
		}
		.card {
			background: transparent;
			border: none;
			overflow: visible;

			.card-image {
				border-radius: 50%;
				border: 5px solid #fff;
				box-shadow: 4px 4px 20px 10px rgba(#000, 0.05);
				max-width: 370px;
			}
			.card-body {
				text-align: center;
				.card-title {
					color: $black;
					text-align: center;
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					@include media-breakpoint-down(lg) {
						font-size: 16px;
					}
				}
				.card-description,
				.card-subtitle {
					display: none;
				}
			}
		}
	}
	div.read-more {
		display: flex;
		flex-flow: column;
		justify-content: center;
		a.read-more {
			@extend .btn, .btn-primary;
			background: $green-dark;
			margin: 3vh auto 0 auto;
			border: none;
			&:hover {
				background: $green-light;
				color: $green-dark;
			}
			@include media-breakpoint-down(lg) {
				margin: 0 auto;
			}
		}
	}
}

// eyecatcher-content-section
.eyecatcher-content-section {
	padding-bottom: 9vh;
	overflow: hidden;
	@include media-breakpoint-down(lg) {
		padding-bottom: 22vh;
	}
	.owl-carousel {
		.owl-nav {
			@extend .container;
			position: absolute;
			bottom: 0;
			left: 70%;
			height: 9vh;
			transform: translate(0%, 50%);
			width: 115px;
			@include media-breakpoint-down(lg) {
				transform: translate(-50%, 150%);
				left: 50%;
				width: 100px;
			}
			i {
				font-weight: 200;
				background: transparent;
				color: $white;
				border: 1px solid $white;
				border-radius: 50%;
				width: 60px;
				aspect-ratio: 1/1;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s ease;
				@include media-breakpoint-down(lg) {
					width: 45px;
					font-size: 12px;
				}
				&::before {
					content: "\f061";
				}
			}
			.owl-prev i {
				transform: scaleX(-1);
			}
		}
		.owl-stage-outer {
			overflow: visible;
		}
		.item {
			padding-bottom: 0 !important;
		}
		.owl-dots {
			display: none;
		}
		.owl-item {
			min-height: 74vh;
		}
		.owl-caption-holder {
			height: 100%;
			display: flex;
			align-items: flex-end;
			@include media-breakpoint-down(lg) {
			}

			.owl-caption {
				background: $blue-dark;
				transform: translateY(50%);
				border-radius: 10px;
				text-align: left;
				padding: 1.5rem;
				padding-right: 40%;
				@include media-breakpoint-down(lg) {
					padding: 1.5rem;
					padding-bottom: 20vh;
					text-align: center;
					max-width: calc(100% - 30px);
					margin: auto;
				}
				.owl-title {
					color: #fff;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
				.owl-description {
					color: #fff;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
				.owl-subtitle,
				.owl-btn {
					display: none;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0 !important;

	.container-one-column {
		.container-holder {
			.wysiwyg {
				max-width: 750px;
				margin-right: auto;
			}
			margin: 0 auto 0 0;
			.column {
				padding-left: 0;
				@include media-breakpoint-down(lg) {
					padding-left: 15px !important;
					padding-right: 15px !important;
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				font-weight: 700;
				color: $green-dark;
				display: flex;
				align-items: center;
			}

			.icon {
				margin-right: 0.5rem;

				background: $white;
				width: 2rem !important;
				display: flex;
				align-items: center;
				justify-content: center;
				aspect-ratio: 1/1;
				border-radius: 50%;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
&.accommodation-book {
	.content-section {
		margin: 0;
		padding: 0 0 6vh 0;
	}
}

&:not(.accommodation-detail) {
	.content-section {
		margin: 6vh 0;
		.container {
			.one {
				flex-basis: 50% !important;
				max-width: 50% !important;
				@include media-breakpoint-down(lg) {
					flex-basis: 100% !important;
					max-width: 100% !important;
				}
			}
			.two {
				flex-basis: 50% !important;
				max-width: 50% !important;
				@include media-breakpoint-down(lg) {
					flex-basis: 100% !important;
					max-width: 100% !important;
				}
			}
			.three {
				flex-basis: 100% !important;
				max-width: 100% !important;
				@include media-breakpoint-down(lg) {
					flex-basis: 100% !important;
					max-width: 100% !important;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.column {
				padding-left: 30px !important;
				padding-right: 30px !important;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;
	position: relative;

	background-image: url("/images/twirl-2.svg");
	background-repeat: no-repeat;
	background-position: center right;
	background-size: contain;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
	@include media-breakpoint-down(lg) {
		.column {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
	@include media-breakpoint-down(lg) {
		.column {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
	@include media-breakpoint-down(lg) {
		.column {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;
	position: relative;
	z-index: 995;

	h2 {
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(lg) {
		.column {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
	@include media-breakpoint-down(lg) {
		.column {
			padding-left: 30px !important;
			padding-right: 30px !important;
		}
	}
}

// landingpage
&.landing-page {
	.content-section + .bg-dark {
		position: relative;
		background: #d0e6de !important;
		* {
			z-index: 200;
		}
	}
}
