// mini-sab

.mini-sab {
	z-index: 800;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		
		@include media-breakpoint-up(lg) {
			max-width: max-content;
		}

		.container-holder {
			z-index: 800;
			margin-top: -2px;
			flex: 1;
			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -9vh;
				// padding-left: 30px;
				// padding-right: 30px;
				// .mini-search-book {
				// 	min-width: 1160px;
				// }
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
			div.tabs {
				display: block;

				ul.vocabulary-term-list.tabs {
					display: flex;
					gap: 0.45rem;
					transform: translateY(2px);
					// justify-content: center;
					@include media-breakpoint-down(lg) {
						margin-left: 1.5rem;
					}
					li {
						border-radius: 10px 10px 0 0;
						padding: 1rem 1rem 0.85rem 1rem;
						background: #fff;
						color: #016ab6;
						font-size: 13px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						@include media-breakpoint-down(lg) {
							padding: 10px;
						}
						&.active {
							background: #f5f5f5;
							cursor: default;
							pointer-events: none;
							a {
								color: $yellow;
							}
						}
						a {
							text-decoration: none;
							&:hover,
							&:active {
								color: $yellow;
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		z-index: 300;
		margin-top: -2rem;
		position: sticky;
		top: -4vh;
		.column {
			padding: 0 !important;
		}
		// .mini-search-book {
		// 	width: max-content;
		// }
	}
}
&:not(:has(.mini-search-book)) {
	.tabs {
		display: none !important;
	}
}
