&.accommodation-detail {
    .content-section {
        margin-bottom: 8rem;
    }
}

&:not(.accommodation-detail).accommodation-book {
    .content-section  {
        .column {
            @extend .px-0;
        }
    }
}